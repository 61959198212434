import { Hash } from 'viem'
import { toChecksumAddress } from '@/utils/crypto'

import { isMayBeENSAddress, sleep } from './helpers'


export const normalizeRecipients = async (
  recipients: [Hash | string, string][],
  resolveAddress: (string: string) => Promise<string | null> | string,
) => {
  const list: Array<[Hash, string]> = []
  const { length } = recipients

  // eslint-disable-next-line no-unreachable-loop
  for (let index = 0; index < length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    if (index % 3000 === 0) await sleep(10)

    // supported empty lines: [''] -> ''
    // eslint-disable-next-line no-continue
    if (!recipients[index].toString()) continue

    const [rawAddress, ...arg] = recipients[index]

    let address = toChecksumAddress(rawAddress)
    if (isMayBeENSAddress(rawAddress)) {
      // eslint-disable-next-line no-await-in-loop
      const addressResolved = await resolveAddress(rawAddress)
      address = toChecksumAddress(addressResolved || address)
    } else {
      address = toChecksumAddress(rawAddress)
    }

    list.push([address, ...arg])
  }

  return list
}

import type { Abi } from 'abitype'
import { ContractEventName, GetContractEventsReturnType } from 'viem'

import { NetworkService } from '@/services'

type GetEventsResult<
  TAbi extends Abi,
  TEventName extends ContractEventName<TAbi>
> = GetContractEventsReturnType<TAbi, TEventName>

type FetchLogsArgs<TAbi extends Abi, TEventName extends ContractEventName<TAbi>> = {
  chainId: number;
  toBlock?: bigint;
  fromBlock: bigint;
  getEvents: (from: bigint, to: bigint) => Promise<GetContractEventsReturnType<TAbi, TEventName>>;
};

export async function fetchLogsInChunks<
  TAbi extends Abi,
  TEventName extends ContractEventName<TAbi>
>(params: FetchLogsArgs<TAbi, TEventName>, chunkSize?: bigint): Promise<GetEventsResult<TAbi, TEventName>> {
  let _chunkSize = chunkSize
  try {
    let { toBlock } = params
    if (!toBlock) {
      const { rpcProvider: client } = new NetworkService(params.chainId)

      if (!client) {
        throw new Error('Please init client')
      }

      toBlock = await client.getBlockNumber()
    }

    if (!_chunkSize) {
      _chunkSize = toBlock + 1n - params.fromBlock
    }

    let currentFromBlock = params.fromBlock
    let allLogs: GetEventsResult<TAbi, TEventName> = []

    while (currentFromBlock <= toBlock) {
      const toChunkBlock = currentFromBlock + _chunkSize - 1n
      const currentToBlock = toChunkBlock > toBlock ? toBlock : toChunkBlock


      // eslint-disable-next-line no-await-in-loop
      const logs = await params.getEvents(currentFromBlock, currentToBlock)

      allLogs = allLogs.concat(logs)

      currentFromBlock = currentToBlock + 1n
    }

    return allLogs
  } catch (err) {
    // eslint-disable-next-line
    console.log(`Fetch events error: ${(err as Error).message}, Chunk size: ${String(_chunkSize)}`)
    if (!chunkSize || chunkSize > 1000n) {
      return fetchLogsInChunks(params, chunkSize ? chunkSize - 1000n : 5000n)
    }
    throw err
  }
}

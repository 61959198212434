<template>
  <MLayoutDefault
    :loading="isLoading"
    :connected="isConnected"
    :is-valid-rpc="isValidRpc"
  >
    <template #main="props">
      <router-view v-slot="{ Component }" :class="$s.view">
        <transition name="transition--fade" mode="out-in" appear>
          <keep-alive :include="['PageAirdrops']">
            <component :is="Component" v-bind="props" />
          </keep-alive>
        </transition>
      </router-view>
    </template>
  </MLayoutDefault>
</template>

<script lang="ts">
import {
  ref,
  watch,
  computed,
  onMounted,
  defineComponent,
} from 'vue'

import {
  useCheckRpc,
  useAppStore,
  useWalletStore,
  useGlobalLoading,
  useWalletListener,
  useTxHistoryStore,
} from '@/composable'

import MLayoutDefault from '@/components/layout/MLayoutDefault.vue'


export default defineComponent({
  components: {
    MLayoutDefault,
  },
  setup: () => {
    useWalletListener()

    const appStore = useAppStore()
    const walletStore = useWalletStore()
    const globalLoading = useGlobalLoading()
    const txHistoryStore = useTxHistoryStore()

    const checkRpc = useCheckRpc()

    const isValidRpc = ref(true)

    onMounted(() => {
      void txHistoryStore.$actions.startWaitPendingAll()
    })

    const onCheckRpc = async (rpc: string) => {
      const result = await checkRpc(rpc)

      isValidRpc.value = result === true
    }

    const selectedRpc = computed(() => {
      const { rpcUrls } = appStore.$state
      const selected = rpcUrls.find((_) => _.active) || rpcUrls[0]
      return selected
    })

    watch(selectedRpc, (rpc) => onCheckRpc(rpc.url))

    return {
      isValidRpc,
      isLoading: globalLoading.loading,
      isConnected: walletStore.isConnected,
    }
  },
})
</script>

<style lang="scss" module="$s">
.view {
  flex: 1;
  padding-bottom: 3rem;
}
</style>

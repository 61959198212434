import { RouteRecordRaw } from 'vue-router'

import { t } from '@/utils'
import {
  ROUTE_AIRDROP,
  ROUTE_AIRDROPS,
  ROUTE_AIRDROP_EDIT,
  ROUTE_AIRDROPS_FIND,
  ROUTE_AIRDROP_DETAILS,
} from '@/utils/constants/routes'
import { isValidAddress } from '@/utils/crypto'


const createRoutes = (): RouteRecordRaw[] => [
  {
    path: '/airdrops',
    name: ROUTE_AIRDROPS,
    meta: {
      title: t('page-title.airdrops'),
    },
    component: () => import(
      /* webpackChunkName: "pages.airdrops" */
      './PageAirdrops.vue'
    ),
  },
  {
    path: '/airdrops/find',
    name: ROUTE_AIRDROPS_FIND,
    meta: {
      title: t('page-title.airdrop-find'),
    },
    component: () => import(
      /* webpackChunkName: "pages.airdrops-find" */
      './PageAirdropsFind.vue'
    ),
  },
  {
    path: '/airdrop/:airdropName/details',
    name: ROUTE_AIRDROP_DETAILS,
    meta: {
      title: t('page-title.airdrop-details'),
    },
    props: true,
    component: () => import(
      /* webpackChunkName: "pages.airdrop-details" */
      './PageAirdropDetails.vue'
    ),
  },
  {
    path: '/airdrop/:airdropName/edit',
    name: ROUTE_AIRDROP_EDIT,
    meta: {
      title: t('page-title.airdrop-edit'),
    },
    props: true,
    component: () => import(
      /* webpackChunkName: "pages.airdrop-edit" */
      './PageAirdropEdit.vue'
    ),
  },
  {
    path: '/airdrop/:airdropName',
    name: ROUTE_AIRDROP,
    meta: {
      title: t('page-title.airdrop-claim'),
    },
    props: ({ params: p, query: q }) => ({
      recipient: isValidAddress(String(q.recipient)) ? q.recipient : void 0,
      ...p,
    }),
    component: () => import(
      /* webpackChunkName: "pages.airdrop" */
      './PageAirdrop.vue'
    ),
  },
]

const createModule = () => {
  const routes = createRoutes()
  return routes
}

export default createModule

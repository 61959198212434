import { Hash } from 'viem'
import pickBy from 'lodash/pickBy'
import { getEnsAddress } from 'viem/actions'

import { storages } from '@/services'

import { NetworkChainId } from './types'
import type { INetworkConfig } from './types'

import { NETWORK_CONFIGS, NETWORK_MAINNET } from './network-configs'
import { isLink, getStaticRpcProviderMemoized, getRpcProvider } from './utils'


export class NetworkService {
  public readonly settings?: INetworkConfig

  public static getAllNetworks() {
    return NETWORK_CONFIGS
  }

  public static getAllSupportedNetworks() {
    return pickBy(NETWORK_CONFIGS, (value) => (
      value.contracts.multisenderMerkle.address
    ))
  }

  public static getNetworkForSwitch(chainId?: NetworkChainId | string | null) {
    if (!chainId || typeof chainId === 'string') {
      return null
    }

    const settings = NETWORK_CONFIGS[chainId]

    if (!settings) {
      return null
    }

    return {
      chainId: `0x${settings.network.chainId.toString(16)}`,
      chainName: settings.network.name,
      rpcUrls: ([] as string[]).concat(settings.rpc.mainUrl || settings.rpc.url),
      blockExplorerUrls: ([] as string[]).concat(settings.explorer.url),
      nativeCurrency: {
        name: settings.currency.name,
        symbol: settings.currency.symbol,
        decimals: settings.currency.decimals,
      },
    }
  }

  public static getRpcProvider(rpcUrl?: string, chainId?: number) {
    return getRpcProvider(rpcUrl, chainId)
  }

  public static resolveAddress(name: string): Promise<Hash | string | null> | Hash | string {
    const { rpc, network } = NETWORK_MAINNET
    if (!rpc?.url || !network?.chainId) {
      return name
    }

    const client = getRpcProvider(rpc.url, network.chainId)
    if (!client) {
      return name
    }

    return getEnsAddress(client, { name })
  }

  constructor(
    public readonly chainId: NetworkChainId,
  ) {
    this.settings = NETWORK_CONFIGS[chainId]
  }

  public get rpcUrls() {
    if (!this.settings) return []

    const { url } = this.settings.rpc
    const rpcUrls = storages.rpcUrls.get() || {}
    const urlCustom = rpcUrls[this.chainId]

    const defaultSettings = {
      url,
      label: new URL(url).host,
      type: 'default' as const,
      active: !urlCustom,
    }

    const customSettings = {
      url: urlCustom,
      label: 'Custom',
      type: 'custom' as const,
      active: !!urlCustom,
    }

    return [
      defaultSettings,
      customSettings,
    ]
  }

  public get rpcUrl() {
    const settings = (
      this.rpcUrls.find((_) => _.active)
      || this.rpcUrls[0]
    )

    return settings?.url
  }

  public get rpcProvider() {
    return getStaticRpcProviderMemoized(this.rpcUrl, this.chainId)
  }

  public get explorer() {
    const chain = NETWORK_CONFIGS[this.chainId]
    return chain.explorer.url
  }

  public getExplorerLink(
    address: Hash,
    type: 'tx' | 'token' | 'address' | 'block',
    chainId = this.chainId,
  ) {
    const { explorer } = NETWORK_CONFIGS[chainId]
    const prefix = explorer[type] || type

    return [
      isLink(prefix) ? void 0 : explorer.url,
      prefix,
      address,
    ].filter(Boolean).join('/')
  }

  public setRpcUrl(url: string) {
    const rpcUrls = storages.rpcUrls.get(void 0) || {}

    if (url === this.settings?.rpc.url) {
      delete rpcUrls[this.chainId]
    } else {
      rpcUrls[this.chainId] = url
    }

    storages.rpcUrls.set(rpcUrls)
  }
}

/* eslint-disable class-methods-use-this */
// https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3---manual-initialization

import type { App } from 'vue'
import type { Router } from 'vue-router'
import type { Primitive } from '@sentry/types'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import snakeCase from 'lodash/snakeCase'
import uniq from 'lodash/uniq'

import { NetworkChainId, NetworkService } from '@/services'
import { TxTypes, getTransactionError, isUsersReject } from '@/utils'


type ITxHistory = ReturnType<typeof import('@/composable').useTxHistoryStore>['list']['value'][number]

const SITE_ORIGIN = process.env.VUE_APP_SITE_ORIGIN.replace('https://', '')
const API_AIRDROP = process.env.VUE_APP_API_AIRDROP.replace('https://', '')

const NOT_ALLOWED_CONSOLES = [
  '[App sw]:',
]

const SENTRY_RELEASE = process.env.NODE_ENV === 'development'
  ? 'localhost'
  : process.env.VUE_APP_SENTRY_RELEASE

const TOKEN_APIS = (() => {
  const list = Object.values(NetworkService.getAllNetworks())
    .reduce((acc, { tokenApis }) => {
      tokenApis.forEach((_) => acc.push(_.url))
      return acc
    }, [] as string[])

  return uniq(list)
})()

class SentryLogger {
  public init(app: App, router: Router) {
    Sentry.init({
      app,
      beforeBreadcrumb: (breadcrumb) => {
        if (breadcrumb.category === 'console') {
          const isAllowed = !NOT_ALLOWED_CONSOLES
            .some((message) => breadcrumb.message?.includes(message))

          return isAllowed ? breadcrumb : null
        }

        if (breadcrumb.category === 'fetch') {
          const url = breadcrumb?.data?.url as string
          const status_code = breadcrumb?.data?.status_code as number

          const isAllowedUrl = (
            false
            || (new RegExp(SITE_ORIGIN, 'i').test(url) && !/\.svg$/.test(url))
            || (new RegExp(API_AIRDROP, 'i').test(url))
            || TOKEN_APIS.some((api) => new RegExp(api, 'i').test(url))
          )

          return isAllowedUrl || status_code !== 200 ? breadcrumb : null
        }

        return breadcrumb
      },
      // debug: window.location.hostname === 'localhost',
      // allowUrls: [],
      // denyUrls: [],
      environment: process.env.VUE_APP_ENV,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      release: SENTRY_RELEASE,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      tracingOptions: {
        trackComponents: true,
      },
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })
  }

  public trackModal(app: App, modalName: string) {
    app.mixin(Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }))

    Sentry.attachErrorHandler(app, {
      attachProps: true,
      logErrors: true,
      trackComponents: false,
      timeout: 0,
      hooks: [],
    })

    const transaction = Sentry.startTransaction({
      op: modalName,
      name: 'MODALS',
      tags: {
        'modal.name': modalName,
      },
    })

    return transaction
  }

  public trackApiRequest(payload: {
    base: string;
    path: string;
    method: 'GET' | 'PUT' | 'POST' | 'DELETE';
    tags: Record<string, Primitive>;
    data?: Record<string, unknown>;
  }) {
    const transaction = Sentry.startTransaction({
      name: [
        'API',
        payload.path,
      ].join(' '),
      op: payload.method,
      tags: {
        'api.base': payload.base.replace(/http(s)?:\/\//, ''),
        ...payload.tags,
        network: NetworkChainId[payload.tags.chainId as NetworkChainId],
      },
      data: payload.data,
    })

    return transaction
  }


  public trackTransaction(payload: ITxHistory & {
    rpcUrl: string;
    provider?: string;
    balance: string;
  }) {
    const transaction = Sentry.startTransaction({
      op: payload.status,
      // traceId: payload.traceId,
      // spanId: payload.spanId,
      status: payload.status,
      name: [
        'TX',
        snakeCase(TxTypes[payload.details.type]),
      ].join(' ').toUpperCase(),
      tags: {
        'tx.type': TxTypes[payload.details.type],
        'tx.from': payload.from,
        'tx.status': payload.status,
        airdropName: payload.details.airdropName,
        ethAccount: payload.from,
        chainId: payload.chainId,
        network: NetworkChainId[payload.chainId as NetworkChainId],
      },
      data: {
        ...payload,
        tx: {
          ...payload.tx,
          data: void 0,
        },
      },
    })

    return transaction
  }

  public capture(error: Error | unknown | string) {
    if (typeof error === 'string') {
      Sentry.captureMessage(error)
      return
    }

    const errorMessage = (
      ''
      // @ts-ignore ts(2571)
      || error?.message
      // @ts-ignore ts(2571)
      || error?.error
      || getTransactionError(error, { origin: true })
    ) as string

    if (isUsersReject(errorMessage)) {
      Sentry.captureMessage(errorMessage)
    } else if (error instanceof Error) {
      Sentry.captureException(error)
    } else {
      Sentry.captureException(errorMessage)
    }
  }

  public addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    return Sentry.addBreadcrumb(breadcrumb)
  }
}

export const sentryLogger = new SentryLogger()

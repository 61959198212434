import { EstimateFeesPerGasReturnType, Hash, PublicClient } from 'viem'

import type { ITxHistory } from '@/store'
import { NETWORK_CONFIGS, NetworkChainId } from '@/services'

import { fromWei } from '@/utils/crypto'
import { bumpValue } from '@/utils/formatters'
import { TransactionReceipt } from '@/store/modules/types'
import { BI_ZERO, GWEI, WEI } from '@/utils/constants/numbers'

export const isMayBeENSAddress = (address: string) => (
  address.toLowerCase().slice(-4) === '.eth'
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sleep = <T = any>(timeout: number, data?: T) => (
  new Promise<T>((resolve) => {
    setTimeout(resolve, timeout, data)
  })
)

export const numberFormat = (
  number: number,
  locale?: string,
  options?: Intl.NumberFormatOptions,
) => (
  new Intl.NumberFormat(locale, options).format(number)
)

export const requestPolling = (request: () => Promise<unknown>, timeout: number) => {
  let isCancelled = false
  let timerId: ReturnType<typeof setTimeout>

  const cancel = () => {
    isCancelled = true
    clearTimeout(timerId)
  }

  const worker = async () => {
    if (isCancelled) return

    try {
      await request()
    } catch {
      //
    }

    if (isCancelled) return
    await sleep(timeout)
    if (isCancelled) return

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    timerId = setTimeout(worker, timeout)
  }

  void worker()


  return {
    cancel,
  }
}

const calcGasLimit = (estimateGas: bigint) => bumpValue(estimateGas)

export const calcFeePerTx = (
  estimateGas: bigint,
  feeData?: Awaited< EstimateFeesPerGasReturnType<'legacy' | 'eip1559'>>,
  withLog?: boolean,
) => {
  if (!feeData) {
    return BI_ZERO
  }

  // .add(feeData.maxPriorityFeePerGas || 0)
  const feePerGas = feeData.maxFeePerGas ? bumpValue(feeData.maxFeePerGas) : feeData.gasPrice || BI_ZERO

  const gasLimit = calcGasLimit(estimateGas)
  const feePerTx = gasLimit * feePerGas

  if (withLog) {
    // eslint-disable-next-line no-console
    console.log('[App info]: calcFeePerTx', {
      gasLimit: Number(gasLimit),
      estimateGas: Number(estimateGas),
      feePerTx: fromWei(feePerTx, WEI),
      gasPrice: fromWei(feeData.gasPrice || BI_ZERO, GWEI),
      maxFeePerGas: fromWei(feeData.maxFeePerGas || BI_ZERO, GWEI),
      maxPriorityFeePerGas: fromWei(feeData.maxPriorityFeePerGas || BI_ZERO, GWEI),
    })
  }

  return feePerTx
}

export const getGasLimitWithCheckBalance = async (
  estimateGasPromise: Promise<bigint>,
  provider: PublicClient,
  ethAccount: Hash,
  isInjectedWallet?: boolean,
) => {
  if (!provider.chain) {
    throw new Error('Provider chain is undefined')
  }

  const chainId = provider.chain.id as NetworkChainId
  const gasFetchType = (NETWORK_CONFIGS[chainId].network.EIP1559 ? 'eip1559' : 'legacy')

  const [estimateGas, feeData, balance] = await Promise.all([
    estimateGasPromise,
    provider?.estimateFeesPerGas({ chain: provider.chain, type: gasFetchType }),
    isInjectedWallet
      ? provider?.getBalance({ address: ethAccount })
      : new Promise<undefined>((resolve) => { resolve(undefined) }),
  ])

  const gasLimit = calcGasLimit(estimateGas)
  const feePerTx = calcFeePerTx(estimateGas, feeData, true)

  const requiredBalance = bumpValue(feePerTx)
  if (balance && isInjectedWallet && requiredBalance > balance) {
    const error = new Error('Insufficient balance found')
    throw error
  }

  return { gasLimit, estimateGas, ...feeData }
}

export const t = (message: string) => message

export const isInfinityWei = (value?: string | null) => !!(
  value && value.length > 36
)

export const createWaitPrevProcess = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let instanceInner: Promise<any>

  const waitPrevProcess = async <T>(
    instance?: Promise<T>,
  ): Promise<T> => {
    try {
      await instanceInner
    } catch {
      // do nothing
    }

    instanceInner = instance || Promise.resolve(undefined)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return instanceInner
  }

  return waitPrevProcess
}

export function addressComparison(address1?: Hash | string | null, address2?: Hash | string | null) {
  return address1?.toLowerCase() === address2?.toLowerCase()
}


export function detectAccountTx(tx: ITxHistory | TransactionReceipt, account?: string) {
  if (!account) {
    return false
  }

  const isFromAddress = addressComparison(tx?.from, account)
  const isToAddress = addressComparison(tx?.to, account)

  return isFromAddress || isToAddress
}

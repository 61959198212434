import { Hash } from 'viem'

import { formatWeiHuman } from '@/utils'


export const transformAirdrop = (
  data: {
    owner: Hash;
    root: Hash;
    total: bigint;
    claimed: bigint;
    cancelled: boolean;
    tokenAddress: Hash;
  },
  decimals: number,
) => ({
  owner: data.owner,
  root: data.root,
  tokenAddress: data.tokenAddress,
  cancelled: data.cancelled,

  claimed: data.claimed.toString(),
  claimedHuman: formatWeiHuman(data.claimed, decimals),
  total: data.total.toString(),
  totalHuman: formatWeiHuman(data.total, decimals),
})


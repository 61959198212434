/* eslint no-await-in-loop: 0 */
/* eslint no-continue: 0 */

import type { IValidatorRule1, IValidatorRule2 } from './types'

import uniq from 'lodash/uniq'

import { NetworkService, ERC20TokenService } from '@/services'

import { t, sleep, isMayBeENSAddress } from '@/utils'
import { isValidAddress, toChecksumAddress } from '@/utils/crypto'

const BATCH_COUNT = 3000
const BATCH_TIME = 10

// only numbers without exponent and hex
const createOnlyNumbers = (decimals: number) => {
  const regExp = new RegExp(`^\\d+(\\.\\d{0,${decimals}})?$`, 'gm')

  return (amount: string | number) => {
    regExp.lastIndex = 0
    return regExp.test(amount.toString())
  }
}

export const urlRule: IValidatorRule1<string | void> = {
  validate: (url) => !!url && /^http(s)?:\/\//.test(url),
  getMessage: () => t('validator.invalid-url'),
}

export const connectionRule: IValidatorRule1<{ isConnected: boolean }> = {
  validate: (wallet) => wallet.isConnected,
  getMessage: () => t('validator.token-connect'),
}

export const addressRequiredRule: IValidatorRule1<string | null> = {
  validate: (address) => !!(address && address.length),
  getMessage: () => t('validator.token-required'),
}

export const addressValidRule: IValidatorRule1<string> = {
  validate: (address) => {
    // do not supported ETH address
    if (+address === 0) return false

    try {
      const addr = toChecksumAddress(address)
      const result = isValidAddress(addr)
      return result
    } catch {
      return false
    }
  },
  getMessage: () => t('validator.token-invalid'),
}

export const ERC20Rule: IValidatorRule2<string, number> = {
  validate: async (address, chainId) => {
    const addr = toChecksumAddress(address)
    const tokenService = ERC20TokenService.buildClass(addr, chainId)

    try {
      await tokenService.updateMeta()
      return true
    } catch (error) {
      //
    }

    try {
      const { rpcUrl } = new NetworkService(chainId)
      const provider = NetworkService.getRpcProvider(rpcUrl, chainId)
      await provider?.getChainId()
      return false
    } catch (error) {
      return {
        value: false,
        result: false,
        message: t('validator.rpc-down'),
      }
    }
  },
  getMessage: (_, data) => (
    // @ts-ignore ts(2339)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    data?.message as string
    || t('validator.token-erc20')
  ),
}

export const recipientsShortRule: IValidatorRule2<unknown[] | null | undefined, number> = {
  validate: (recipients, count) => {
    const list = recipients?.filter(Boolean) || []

    return (
      list.length >= count
      || { value: false, result: false }
    )
  },
  getMessage: () => t('validator.recipients-short-list'),
}

export const recipientsManyColumnsRule: IValidatorRule2<
  ([string, string | number | undefined] | [])[],
  number,
  number[]
> = {
  validate: async (recipients, count) => {
    const result: number[] = []

    for (let index = 0; index < recipients.length; index += 1) {
      if (index % BATCH_COUNT === 0) await sleep(BATCH_TIME)

      // supported empty lines: [''] -> ''
      if (!recipients[index].toString()) continue

      const line = recipients[index]

      const isValidAmount = (
        line.length === count
      )

      if (!isValidAmount) {
        result.push(index)
      }
    }

    result.sort((a, b) => a - b)
    return result.length ? { result, value: false } : true
  },
  getMessage: () => t('validator.recipients-many-columns'),
}

export const recipientsWithAmountFormatRule: IValidatorRule2<
  ([string, string | number | undefined] | [])[],
  number,
  number[]
> = {
  validate: async (recipients, decimals) => {
    const onlyNumbers = createOnlyNumbers(decimals)
    const result: number[] = []

    for (let index = 0; index < recipients.length; index += 1) {
      if (index % BATCH_COUNT === 0) await sleep(BATCH_TIME)

      // supported empty lines: [''] -> ''
      if (!recipients[index].toString()) continue

      const [, amount] = recipients[index]

      const isValidAmount = (
        !!amount
        && onlyNumbers(amount.toString())
        && +amount > 0
      )

      if (!isValidAmount) {
        result.push(index)
      }
    }

    result.sort((a, b) => a - b)
    return result.length ? { result, value: false } : true
  },
  getMessage: () => t('validator.recipients-bad-amount'),
}

export const recipientsWithAddressFormatRule: IValidatorRule1<
  [string, string | number | undefined][],
  number[]
> = {
  validate: async (recipients) => {
    const result: number[] = []

    for (let index = 0; index < recipients.length; index += 1) {
      if (index % BATCH_COUNT === 0) await sleep(BATCH_TIME)

      // supported empty lines: [''] -> ''
      if (!recipients[index].toString()) continue

      const [address] = recipients[index]

      if (isMayBeENSAddress(address)) continue

      const isValid = await addressValidRule.validate(address)

      if (!isValid) {
        result.push(index)
      }
    }

    result.sort((a, b) => a - b)
    return result.length ? { result, value: false } : true
  },
  getMessage: () => t('validator.recipients-bad-address'),
}

export const recipientsWithEnsAddressFormatRule: IValidatorRule1<
  [string, string | number | undefined][],
  number[]
> = {
  validate: async (recipients) => {
    const result: number[] = []

    for (let index = 0; index < recipients.length; index += 1) {
      if (index % BATCH_COUNT === 0) await sleep(BATCH_TIME)

      const [address] = recipients[index]

      if (!isMayBeENSAddress(address)) continue

      const addressResolved = await NetworkService.resolveAddress(address)
      if (addressResolved) continue

      result.push(index)
    }

    result.sort((a, b) => a - b)
    return result.length ? { result, value: false } : true
  },
  getMessage: () => t('validator.recipients-bad-ens-address'),
}

export const recipientsWithAddressDuplicateRule: IValidatorRule1<
  [string, string | number | undefined][],
  number[]
> = {
  validate: async (recipients) => {
    const map = new Map<string, number>()
    let result: number[] = []

    for (let index = 0; index < recipients.length; index += 1) {
      if (index % BATCH_COUNT === 0) await sleep(BATCH_TIME)

      // supported empty lines: [''] -> ''
      if (!recipients[index].toString()) continue

      const [rawAddress] = recipients[index]
      let address = rawAddress.toLowerCase()

      if (isMayBeENSAddress(rawAddress)) {
        const addressResolved = await NetworkService.resolveAddress(address)
        // filters bad ens need before this validation
        if (!addressResolved) continue
        address = addressResolved
      }

      address = address.toLowerCase().replace(/^0x/, '')

      if (map.has(address)) {
        result.push(map.get(address) as number)
        result.push(index)
      }

      map.set(address, index)
    }

    result = uniq(result).sort((a, b) => a - b)
    return result.length ? { result, value: false } : true
  },
  getMessage: () => t('validator.recipients-duplicate-address'),
}

export const rpcChainIdCorrectedRule: IValidatorRule2<string | void, number | void, string> = {
  validate: async (url, chainId) => {
    if (!url || !chainId) {
      return {
        value: false,
        result: t('validator.token-connect'),
      }
    }

    const provider = NetworkService.getRpcProvider(url, chainId)

    try {
      if (!provider) {
        throw new Error('Please init provider')
      }

      const _chainId = await provider.getChainId()
      if (+_chainId === chainId) return true

      return {
        value: false,
        result: t('validator.rpc-different'),
      }
    } catch (error) {
      return {
        value: false,
        result: t('validator.rpc-down'),
      }
    }
  },
  getMessage: (_, r) => (
    r.value
      ? t('validator.token-connect')
      : r.result
  ),
}

export const emailRule: IValidatorRule1<string> = {
  validate: (email) => {
    // https://stackoverflow.com/a/12199843

    // eslint-disable-next-line no-useless-escape
    const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return pattern.test(email)
  },
  getMessage: () => t('validator.email'),
}

export const websiteProtocolRule: IValidatorRule1<string> = {
  validate: (website) => (
    /^http(s)?:\/\//i.test(website.trim())
  ),
  getMessage: () => t('validator.website-protocol'),
}

export const websiteRule: IValidatorRule1<string> = {
  validate: (website) => {
    // https://stackoverflow.com/a/5717133
    const pattern = new RegExp('^(http(s)?:\\/\\/)' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

    return pattern.test(website.trim())
  },
  getMessage: () => t('validator.website'),
}

export const twitterLoginRule: IValidatorRule1<string> = {
  validate: (twitter) => {
    const result = websiteProtocolRule.validate(twitter) || websiteRule.validate(twitter)

    return !result
  },
  getMessage: () => t('validator.twitter'),
}

export const twitterAllowedSymbolsRule: IValidatorRule1<string> = {
  validate: (twitter) => {
    const result = (
      // numbers, letters, underscore
      twitter.trim().replace(/(\d+|[a-zA-Z]|_+)/g, '').length === 0
    )

    return result
  },
  getMessage: () => t('validator.twitter-symbols'),
}

export const telegramLoginRule: IValidatorRule1<string> = {
  validate: (telegram) => {
    const result = websiteProtocolRule.validate(telegram) || websiteRule.validate(telegram)

    return !result
  },
  getMessage: () => t('validator.telegram'),
}

export const telegramAllowedSymbolsRule: IValidatorRule1<string> = {
  validate: (telegram) => {
    const result = (
      // numbers, letters, underscore
      telegram.trim().replace(/(\d+|[a-zA-Z]|_+)/g, '').length === 0
    )

    return result
  },
  getMessage: () => t('validator.telegram-symbols'),
}

export const contactsEmptyRule: IValidatorRule1<{
  email?: string;
  website?: string;
  twitter?: string;
  telegram?: string;
}> = {
  validate: (data) => {
    const result = !!(data.email?.trim() || data.website?.trim() || data.twitter?.trim() || data.telegram?.trim())

    return result
  },
  getMessage: () => t('validator.contacts-empty'),
}

export const descriptionEmptyRule: IValidatorRule1<string> = {
  validate: (description) => !!description?.trim(),
  getMessage: () => t('validator.description-empty'),
}

import { t } from './helpers'
import { TransactionStatus } from '@safe-global/safe-gateway-typescript-sdk'
import { Hash } from 'viem'


export enum TxTypes {
  approveToken,
  createAirdrop,
  cancelAirdrop,
  claim,
}

export enum TxStatuses {
  PENDING = 'pending',
  SUCCESS ='success',
  REVERTED ='reverted',
  CANCELLED = 'cancelled',
}

export const txStatusLabels = {
  [TxStatuses.REVERTED]: t('table-history.status-failed'),
  [TxStatuses.SUCCESS]: t('table-history.status-success'),
  [TxStatuses.PENDING]: t('table-history.status-pending'),
  [TxStatuses.CANCELLED]: t('table-history.status-cancelled'),
  // [TxStatuses.notFoundTx]: t('table-history.status-notFoundTx'),
  UNKNOWN: t('table-history.status-unknown'),
}


export const gnosisTxStatusLabels = {
  [TransactionStatus.FAILED]: t('table-history.status-failed'),
  [TransactionStatus.SUCCESS]: t('table-history.status-success'),
  [TransactionStatus.CANCELLED]: t('table-history.status-cancelled'),
  [TransactionStatus.AWAITING_EXECUTION]: t('table-history.status-awaiting-execution'),
  [TransactionStatus.AWAITING_CONFIRMATIONS]: t('table-history.status-awaiting-confirmations'),
}

export const txTypeLabels = {
  [TxTypes.approveToken]: t('tx.status-approve-t'),
  [TxTypes.createAirdrop]: t('tx.status-create-a'),
  [TxTypes.cancelAirdrop]: t('tx.status-cancel-a'),
  [TxTypes.claim]: t('tx.status-claim-a'),
  UNKNOWN: t('tx.status-unknown'),
}

type IToken = {
  symbol: string;
  address: Hash;
}

export type ITxDetails =
  | ReturnType<typeof createTxApproveTokenDetails>
  | ReturnType<typeof createTxCreateAirdropDetails>
  | ReturnType<typeof createTxCancelAirdropDetails>
  | ReturnType<typeof createTxCancelAirdropDetails>

export const createTxApproveTokenDetails = (
  token: IToken,
  amount: string,
  airdropName?: string,
) => ({
  type: TxTypes.approveToken,
  token,
  airdropName,
  amount,
})


export const createTxCreateAirdropDetails = (
  airdropName: string,
) => ({
  type: TxTypes.createAirdrop,
  airdropName,
})

export const createTxCancelAirdropDetails = (
  airdropName: string,
) => ({
  type: TxTypes.cancelAirdrop,
  airdropName,
})

export const createTxClaimDetails = (
  token: IToken,
  airdropName: string,
  amount: string,
) => ({
  type: TxTypes.claim,
  airdropName,
  token,
  amount,
})


import type { Store } from 'vuex'
import {
  Module,
  Actions,
  Getters,
  Mutations,
  createComposable,
} from 'vuex-smart-module'

import { markRaw } from 'vue'
import { Hash, SendTransactionParameters, WalletClient } from 'viem'
import { TransactionStatus } from '@safe-global/safe-gateway-typescript-sdk'

import { cancelPrevious, memoizeAsync } from 'utils-decorators'

import type { ComposableFn, Context2 } from 'vuex-smart-module/lib'

import {
  sentryLogger,
  IAirdropPublic,
  ApiAirdropService,
  MerkleTreeService,
} from '@/services'

import { isValidAddress } from '@/utils/crypto'
import { createTxCancelAirdropDetails, createTxClaimDetails, formatWeiHuman } from '@/utils'

import {
  isCancel,
  createSetState,
  createPagination,
  IAirdropPublicPagination,
} from './types'

import { WalletStoreModule } from './wallet'
import { TxHistoryStoreModule } from './txHistory'

type S = AirdropState
type G = AirdropGetters
type M = AirdropMutations
type A = AirdropActions

type IAirdropData = {
  claimed: string;
  claimedHuman: string;
  owner: Hash;
  root: string;
  cancelled: boolean;
  tokenAddress: Hash;
  total: string;
  totalHuman: string;
}

export class AirdropState {
  public airdropPublic: IAirdropPublic | null = null

  public airdrop: IAirdropData | null = null

  public claimEvent: {
    transactionHash?: Hash;
  } | null = null

  public recipients: [Hash, string][] = []

  public recipientsPagination: IAirdropPublicPagination | null = createPagination()

  public claimEventMap: Record<string, { transactionHash?: Hash }> | null = null

  public claimCount: number | null = null

  public chainId: number | null = null
}

class AirdropGetters extends Getters<S> {
  private walletStore!: Context2<typeof WalletStoreModule>

  public $init(store: Store<unknown>) {
    this.walletStore = WalletStoreModule.context(store) as Context2<typeof WalletStoreModule>
  }

  public get isOwner() {
    const owner = this.state.airdrop?.owner.toLowerCase()
    const ethAccount = this.walletStore.state.ethAccount?.toLowerCase()

    return !!(owner && ethAccount === owner)
  }

  public get reward() {
    const { airdropPublic } = this.state
    if (!airdropPublic) return null

    const { ethAccount } = this.walletStore.state
    const [settings] = airdropPublic.treeRecipients
    if (!settings) return null

    return {
      account: ethAccount,
      amount: settings.amount,
      proof: settings.proof,
      amountHuman: formatWeiHuman(settings.amount, airdropPublic.token.decimals),
    }
  }

  public get watchAssetDisabled() {
    return (
      !this.walletStore.getters.isConnected
      || this.state.airdropPublic?.chainId !== this.walletStore.state.chainId
    )
  }
}

class AirdropMutations extends Mutations<S> {
  public setState(options: {
    [K in keyof S]: { k: K; v: S[K] }
  }[keyof S]) {
    // @ts-ignore: TODO ts(2322)
    this.state[options.k] = options.v
  }
}

class AirdropActions extends Actions<S, G, M, A> {
  private walletStore!: Context2<typeof WalletStoreModule>

  private txHistoryStore!: Context2<typeof TxHistoryStoreModule>

  public $init(store: Store<unknown>) {
    this.walletStore = WalletStoreModule.context(store) as Context2<typeof WalletStoreModule>
    this.txHistoryStore = TxHistoryStoreModule.context(store) as Context2<typeof TxHistoryStoreModule>
  }

  public async updateAirdropPublic(payload: {
    airdropName: string;
    recipient?: Hash;
  }) {
    const setState = createSetState<A>(this)
    const { ethAccount } = this.walletStore.state

    const publicName = this.state.airdropPublic?.publicName
    if (publicName && payload.airdropName !== publicName) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await this.actions.cleanup()
    }

    try {
      const results = await this.actions.getAirdropPublic({ ...payload, ethAccount })

      setState({ k: 'airdropPublic', v: results.airdropPublic })
      setState({ k: 'airdrop', v: results.airdrop })
    } catch (error) {
      if (!isCancel(error)) {
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return true
  }

  @memoizeAsync(2000)
  @cancelPrevious()
  public async getAirdropPublic(payload: {
    recipient?: Hash;
    airdropName: string;
    ethAccount: Hash | null;
  }) {
    const api = new ApiAirdropService()
    const { airdropName, ethAccount } = payload

    const recipient = payload.recipient || isValidAddress(ethAccount) ? ethAccount : null

    const response = await api.getByName(airdropName, recipient)


    if (!response.data) {
      return { airdropPublic: null, airdrop: null }
    }

    const airdropPublic = response.data
    const { chainId, token } = airdropPublic

    const treeService = new MerkleTreeService(chainId)
    const airdrop = await treeService.getAirdrop(airdropName, token.decimals)

    return {
      airdropPublic,
      airdrop,
    }
  }

  public async updateAirdropRecipients(payload: {
    airdropName: string;
    limit?: number;
    page: number;
  }) {
    const setState = createSetState<A>(this)
    const { airdropName } = payload

    const { publicName } = this.state.airdropPublic || {}
    if (publicName && airdropName !== publicName) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await this.actions.cleanup()
    }

    try {
      const result = await this.actions.getAirdropRecipients(payload)
      setState({ k: 'recipients', v: markRaw(result.data) })

      if (result.pagination.count <= result.pagination.limit) {
        setState({ k: 'recipientsPagination', v: null })
      } else {
        setState({ k: 'recipientsPagination', v: result.pagination })
      }
    } catch (error) {
      if (!isCancel(error)) {
        setState({ k: 'recipients', v: [] })
        setState({ k: 'recipientsPagination', v: createPagination() })
        sentryLogger.capture(error)
        return Promise.reject(error)
      }

      return false
    }

    return true
  }


  @cancelPrevious()
  public async getAirdropRecipients(payload: {
    airdropName: string;
    limit?: number;
    page: number;
  }) {
    const { airdropName, limit, page } = { ...createPagination(), ...payload }
    const api = new ApiAirdropService()
    const response = await api.getRecipients(airdropName, { limit, page })

    if (!response.data) {
      return {
        pagination: createPagination(),
        data: [],
      }
    }

    const { data, ...pagination } = response

    return {
      pagination,
      data,
    } as {
      pagination: IAirdropPublicPagination;
      data: typeof data;
    }
  }

  public async checkApproveAndBalanceToken() {
    const { airdropPublic } = this.state
    const { reward } = this.getters

    if (!reward || !airdropPublic || !reward.account) {
      return null
    }

    const payload = {
      amount: reward.amount,
      recipient: reward.account,
      owner: airdropPublic.owner,
      tokenAddress: airdropPublic.token.address,
    }

    const treeService = new MerkleTreeService(airdropPublic.chainId)
    return treeService.checkApproveAndBalanceToken(payload)
  }

  public async claim(client: WalletClient) {
    const { reward } = this.getters
    const { airdropPublic } = this.state
    const { ethAccount } = this.walletStore.state

    if (!reward || !airdropPublic || !ethAccount || !reward.account) {
      return null
    }
    const { chainId, publicName: airdropName } = airdropPublic

    const args: [string, Hash[], string, Hash, Hash] = [
      airdropName,
      reward.proof,
      reward.amount,
      reward.account,
      ethAccount,
    ]

    if (!args.every(Boolean)) return null

    const treeService = new MerkleTreeService(chainId)
    const txData = await treeService.claim(args, this.walletStore.getters.isInjectedWallet)
    const hash = await client.sendTransaction(txData as SendTransactionParameters)
    const details = createTxClaimDetails(airdropPublic.token, airdropName, reward.amount)

    const isSafeTx = await this.walletStore.actions.detectGnosisSafeTx({ hash, chainId })

    if (isSafeTx) {
      const tx2 = await this.txHistoryStore.actions.addGnosisTx({
        hash,
        details,
        chainId,
        multisigAddress: ethAccount,
        token: airdropPublic.token.address,
        status: TransactionStatus.AWAITING_CONFIRMATIONS,
      })
      return tx2
    }

    const tx = await this.txHistoryStore.actions.handleWatchTxData({ txHash: hash, chainId })
    const tx2 = this.txHistoryStore.actions.add({ tx, chainId, details })

    return tx2
  }

  public async cancelAirdrop(client: WalletClient) {
    const { airdropPublic } = this.state
    const { ethAccount } = this.walletStore.state

    if (!ethAccount || !airdropPublic) return null
    const { chainId, publicName: airdropName } = airdropPublic

    const treeService = new MerkleTreeService(chainId)

    const txData = await treeService.cancelAirdrop(airdropName, ethAccount, this.walletStore.getters.isInjectedWallet)
    const hash = await client.sendTransaction(txData as SendTransactionParameters)

    const details = createTxCancelAirdropDetails(airdropName)
    const isSafeTx = await this.walletStore.actions.detectGnosisSafeTx({ hash, chainId })

    if (isSafeTx) {
      const tx2 = await this.txHistoryStore.actions.addGnosisTx({
        hash,
        details,
        chainId,
        multisigAddress: ethAccount,
        token: airdropPublic.token.address,
        status: TransactionStatus.AWAITING_CONFIRMATIONS,
      })
      return tx2
    }

    const tx = await this.txHistoryStore.actions.handleWatchTxData({ txHash: hash, chainId })
    const tx2 = this.txHistoryStore.actions.add({ tx, chainId, details })

    return tx2
  }

  public async updateClaimEvent(payload?: {
    recipient?: Hash;
  }) {
    const setState = createSetState<A>(this)
    const { airdropPublic } = this.state
    const { ethAccount } = this.walletStore.state
    if (!airdropPublic) return false

    const recipient = payload?.recipient || ethAccount
    if (!recipient) return false

    const { chainId, publicName: airdropName, txHash } = airdropPublic

    try {
      // eslint-disable-next-line object-curly-newline
      const props = { chainId, airdropName, recipient, txHash }
      const event = await this.actions.getClaimEvent(props)
      setState({ k: 'claimEvent', v: event || null })
    } catch (error) {
      setState({ k: 'claimEvent', v: null })
      if (!isCancel(error)) {
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return true
  }


  @cancelPrevious()
  public async getClaimEvent(payload: {
    airdropName: string;
    chainId: number;
    recipient: Hash;
    txHash: Hash;
  }) {
    // eslint-disable-next-line object-curly-newline
    const { airdropName, chainId, recipient, txHash } = payload
    const treeService = new MerkleTreeService(chainId)

    const event = await treeService.checkClaimEvent(airdropName, recipient, txHash)

    return event
  }

  public async updateClaimedMap() {
    const setState = createSetState<A>(this)
    const { airdropPublic, recipients } = this.state
    const { chainId, publicName: airdropName, txHash } = airdropPublic || {}

    const isNotValidData = (
      !airdropName
      || !chainId
      || recipients.length === 0
    )

    if (isNotValidData) {
      setState({ k: 'claimEventMap', v: null })
      setState({ k: 'claimCount', v: null })
      return false
    }

    try {
      const result = await this.actions.getClaimedEventsMap({
        txHash,
        chainId,
        recipients,
        airdropName,
      })

      const recipientCount = this.state.recipientsPagination?.count
      const count = (recipients.length === recipientCount || !recipientCount) ? result?.count : null

      setState({ k: 'claimEventMap', v: result?.data || null })
      setState({ k: 'claimCount', v: count ?? null })
    } catch (error) {
      setState({ k: 'claimEventMap', v: null })
      setState({ k: 'claimCount', v: null })

      if (!isCancel(error)) {
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return true
  }


  @cancelPrevious()
  public async getClaimedEventsMap(payload: {
    airdropName: string;
    chainId: number;
    recipients: [Hash, string][];
    txHash?: Hash;
  }) {
    // eslint-disable-next-line object-curly-newline
    const { airdropName, chainId, recipients, txHash } = payload

    const treeService = new MerkleTreeService(chainId)
    const data = await treeService.getClaimedMap({ airdropName, recipients, txHash })
    return data
  }

  public cleanup() {
    const setState = createSetState<A>(this)
    const stateInitial = new AirdropState()

    Object.entries(stateInitial).forEach((data) => {
      const [k, v] = data as [keyof AirdropState, AirdropState[keyof AirdropState]]
      setState({ k, v } as Parameters<AirdropMutations['setState']>[0])
    })
  }
}

const storeModule = new Module({
  namespaced: true,
  state: AirdropState,
  getters: AirdropGetters,
  mutations: AirdropMutations,
  actions: AirdropActions,
})

const useStore = createComposable(storeModule) as ComposableFn<typeof storeModule>

export {
  storeModule as AirdropStoreModule,
  useStore as useAirdropStoreModule,
}

// about contract https://github.com/mds1/multicall

import { getContract, Hash } from 'viem'

import { sleep } from '@/utils'
import { NetworkService } from '@/services'

import { ETH_CALL_BLOCK_NUMBER_LIMIT } from './utils'

import Multicall3Abi from '@/abi/Multicall3'


const MAX_LIMIT_CALL = 400

export type MulticallParams = Array<{
  target: Hash;
  callData: Hash;
  allowFailure: boolean;
}>

export class Multicall3Service {
  private constructor(
    public readonly chainId: number,
    private readonly address: Hash,
    private readonly networkService: NetworkService,
  ) {
  }

  public static buildClass(chainId?: number | null) {
    if (!chainId) return null

    const networkService = new NetworkService(chainId)
    const multicall3 = networkService.settings?.contracts.multicall3
    if (!multicall3) return null

    return new Multicall3Service(chainId, multicall3.address, networkService)
  }

  private get contract() {
    const networkService = new NetworkService(this.chainId)

    const client = networkService.rpcProvider

    if (!client) {
      return null
    }
    return getContract({ address: this.address, abi: Multicall3Abi, client })
  }

  public async aggregateMulticallBatchGenerator<R>(
    generator: (limit: number) => Generator<MulticallParams>,
    estimateGas: number | bigint,
    decoder: (value: Hash) => R,
  ) {
    // const { gasLimit } = await contract.provider.getBlock('latest')
    const blockGasLimit = this.networkService.settings?.blockGasLimit

    // 80% of gasLimit
    let limit = !blockGasLimit ? 50 : ETH_CALL_BLOCK_NUMBER_LIMIT * (blockGasLimit / Number(estimateGas))
    limit = limit > MAX_LIMIT_CALL ? MAX_LIMIT_CALL : Math.floor(limit)

    const iterator = generator(limit)
    let calldataList: MulticallParams[] = []
    const promises: Promise<R[]>[] = []

    const runAggregateMulticall = () => {
      calldataList.forEach((call) => {
        const promise = this.aggregateMulticall<R>(call, decoder)
        promises.push(promise)
      })

      calldataList = []
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const calldata of iterator) {
      calldataList.push(calldata)
      await sleep(40)

      // eslint-disable-next-line no-continue
      if (calldataList.length < 5) continue

      runAggregateMulticall()
    }

    runAggregateMulticall()

    const results = await Promise.all(promises)
    return results.flat()
  }

  public async aggregateMulticall<R>(
    calls: MulticallParams,
    decoder: (value: Hash) => R,
  ) {
    if (!this.contract) {
      throw new Error('Please init contract')
    }
    const response = await this.contract.simulate.aggregate3([calls])
    const result = response.result.map((r) => decoder(r.returnData))
    return result
  }
}

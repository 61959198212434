<template>
  <MExternalLink
    v-if="href"
    :invert="invert"
    :external="external"
    :href="href"
    :text="text || addressRef"
  >
    <slot />
  </MExternalLink>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue'

import { Hash } from 'viem'

import { NetworkService } from '@/services'

import MExternalLink from './MExternalLink.vue'


const SUPPORTED_TYPES = [
  'tx',
  'token',
  'address',
  'block',
  'multisender',
] as const

export default defineComponent({
  components: {
    MExternalLink,
  },
  props: {
    invert: Boolean,
    external: Boolean,
    text: String,
    chainId: {
      type: Number,
      required: true,
    },
    type: {
      type: String as PropType<typeof SUPPORTED_TYPES[number]>,
      required: true,
      validator: (value: typeof SUPPORTED_TYPES[number]) => (
        SUPPORTED_TYPES.includes(value)
      ),
    },
    address: {
      type: String as PropType<Hash>,
    },
  },
  setup: (props) => {
    const addressRef = computed(() => {
      const networkService = new NetworkService(props.chainId)
      const isMultisender = props.type === 'multisender'

      return isMultisender
        ? networkService.settings?.contracts.multisenderMerkle.address
        : props.address
    })

    const href = computed(() => {
      const networkService = new NetworkService(props.chainId)
      const isMultisender = props.type === 'multisender'

      const address = addressRef.value
      const type = isMultisender ? 'address' : props.type

      if (!address) return ''
      return networkService.getExplorerLink(address, type)
    })

    return {
      addressRef,
      href,
    }
  },
})
</script>

import type { Actions, Mutations, Getters } from 'vuex-smart-module'

import { Hash } from 'viem'
import { CanceledPromise } from 'utils-decorators'

import { TxStatuses } from '@/utils'
import { DEFAULT_PAGINATION_LIMIT } from '@/utils/constants/params'


export type IAirdropPublicPagination = {
  page: number;
  count: number;
  limit: number;
  offset: number | undefined;
  nextPage: number | undefined;
  prevPage: number | undefined;
  // chainId: number | undefined;
}

export const createPagination = (): IAirdropPublicPagination => ({
  page: 1,
  count: 0,
  limit: DEFAULT_PAGINATION_LIMIT,
  prevPage: undefined,
  nextPage: undefined,
  offset: undefined,
})

export const isCancel = (error: unknown) => (
  error instanceof CanceledPromise
)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface St {
}

interface Mut<S extends St> extends Mutations<S> {
  setState: (options: {
    [K in keyof S]: { k: K; v: S[K] }
  }[keyof S]) => void;
}

export const createSetState = <
  A extends Actions<S, G, M, A>,
  // @ts-ignore ts(4105)
  S extends St = A['state'],
  // @ts-ignore ts(4105)
  G extends Getters<S> = A['getters'],
  // @ts-ignore ts(4105)
  M extends Mut<S> = A['mutations']>(actions: A) => (
    // @ts-ignore ts(4105)
    (options: NonNullable<Parameters<A['mutations']['setState']>[0]>) => {
      // @ts-ignore ts(2445)
      const { mutations, state } = actions
      if (options && state[options.k] !== options.v) {
        mutations.setState(
          // @ts-ignore ts(2345)
          options,
        )
      }
    }
  )


export type Meta = {
  name: string;
  icon: string;
  isGnosisSafe:boolean;
  isAvailableChainSwitch:boolean;
  description?: string;
}

export type WalletDataInput = {
  address: Hash;
  providerName: string;
  meta: Meta;
}

export type TransactionReceipt = {
  from: Hash;
  data: Hash;
  hash: Hash;
  nonce: number;
  value: string;
  to: Hash | null;
  timestamp?: number;
  blockNumber: number;
  status: TxStatuses;
}

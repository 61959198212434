import { FormattedTransaction, Hash, PublicClient } from 'viem'
import {
  getBlock,
  getBlockNumber,
  getTransaction,
  getTransactionCount,
  waitForTransactionReceipt as waitForTransactionReceiptViem,
} from 'viem/actions'

import { sleep, TxStatuses } from '@/utils'
import { NetworkService } from '@/services/NetworkService'
import { TransactionReceipt } from '@/store/modules/types'


type GetTransactionByNonce = {
  address: Hash;
  nonce: number;
  client: PublicClient;
  blockNumber: bigint;
}
export async function getTransactionByNonce({
  client, nonce, address, blockNumber,
}: GetTransactionByNonce): Promise<FormattedTransaction | null> {
  const currentNonce = await getTransactionCount(client, { address }) - 1

  // Transaction still pending
  if (currentNonce < nonce) {
    return null
  }

  const startSearchNonce = await getTransactionCount(client, { address, blockNumber })
  if (nonce <= startSearchNonce - 1) {
    return null
  }

  let maxBlock = await getBlockNumber(client) // latest: chain head
  let minBlock = blockNumber
  while (minBlock < maxBlock) {
    const middleBlock = Math.floor(Number(minBlock + maxBlock) / 2)
    // eslint-disable-next-line no-await-in-loop
    const middleNonce = await getTransactionCount(client, { address, blockNumber: BigInt(middleBlock) })
    if (middleNonce - 1 < nonce) {
      minBlock = BigInt(middleBlock + 1)
    } else {
      maxBlock = BigInt(middleBlock)
    }
  }
  const block = await getBlock(client, { blockNumber: minBlock, includeTransactions: true })
  const transaction = block.transactions.find(
    (blockTx) => blockTx.from.toLowerCase() === address.toLowerCase() && blockTx.nonce === nonce,
  )
  if (!transaction) {
    return null
  }
  return transaction
}

type FindReplacementTxInput = {
  client: PublicClient;
  fromBlock: number;
  tx: { from: Hash; to: Hash | null; nonce: number; value: string };
}

type FindReplacementTxResp = null | {
  hash: Hash;
  status: TxStatuses;
  timestamp: number;
}
async function findReplacementTx({ client, fromBlock, tx }: FindReplacementTxInput): Promise<FindReplacementTxResp> {
  const transaction = await getTransactionByNonce({
    client,
    nonce: tx.nonce,
    address: tx.from,
    blockNumber: BigInt(fromBlock),
  })

  if (!transaction) {
    return null
  }

  const { timestamp } = await getBlock(client, {
    blockNumber: BigInt(fromBlock),
  })

  if (transaction.to?.toLowerCase() !== tx.to?.toLowerCase()) {
    return {
      hash: transaction.hash,
      status: TxStatuses.CANCELLED,
      timestamp: Number(timestamp),
    }
  }

  if (tx.value) {
    if (transaction.value.toString() !== tx.value) {
      return {
        hash: transaction.hash,
        status: TxStatuses.CANCELLED,
        timestamp: Number(timestamp),
      }
    }
  }

  return {
    hash: transaction.hash,
    status: TxStatuses.PENDING,
    timestamp: Number(timestamp),
  }
}


type WaitForTransactionReceiptInput = {
  from: Hash;
  hash: Hash;
  value: string;
  nonce: number;
  rpcUrl: string;
  to: Hash | null;
  chainId: number;
  blockNumber: number;
}
export async function waitForTransactionReceipt(
  params: WaitForTransactionReceiptInput,
  attempt = 0,
): Promise<TransactionReceipt> {
  try {
    let { hash } = params

    const client = NetworkService.getRpcProvider(params.rpcUrl, params.chainId)
    if (!client) {
      throw new Error('Please init provider')
    }


    const replacementTx = await findReplacementTx({
      client,
      fromBlock: params.blockNumber,
      tx: {
        from: params.from,
        to: params.to,
        nonce: params.nonce,
        value: params.value,
      },
    })

    if (replacementTx) {
      if (replacementTx.status !== TxStatuses.PENDING) {
        return {
          data: '0x',
          to: params.to,
          from: params.from,
          nonce: params.nonce,
          value: params.value,
          hash: replacementTx.hash,
          status: replacementTx.status,
          blockNumber: params.blockNumber,
          timestamp: replacementTx.timestamp,
        }
      }
      hash = replacementTx.hash
    }

    const receipt = await waitForTransactionReceiptViem(client, {
      hash,
      retryCount: 1000,
      onReplaced: (replaced) => {
        hash = replaced.replacedTransaction.hash
      },
    })

    const transaction = await getTransaction(client, { hash })

    const block = await getBlock(client, { blockHash: receipt.blockHash })

    return {
      to: transaction.to,
      from: transaction.from,
      data: transaction.input,
      nonce: transaction.nonce,
      hash: receipt.transactionHash,
      timestamp: Number(block.timestamp),
      value: transaction.value.toString(),
      status: receipt.status as TxStatuses,
      blockNumber: Number(receipt.blockNumber),
    }
  } catch (err) {
    if (attempt < 5) {
      await sleep(3_000)
      return waitForTransactionReceipt(params, attempt + 1)
    }
    throw err
  }
}



import type { Store } from 'vuex'

import type { Context2, ComposableFn } from 'vuex-smart-module/lib'

import { Hash } from 'viem'
import {
  Module,
  Getters,
  Actions,
  Mutations,
  createComposable,
} from 'vuex-smart-module'

import { EMPTY_ADDRESS } from '@/utils/constants/crypto'

import { NetworkService } from '@/services'
import { checkIsGnosis } from '@/services/SafeSDKService'

import { createSetState, Meta, WalletDataInput } from './types'

import { AppStoreModule } from './app'
import { TxHistoryStoreModule } from './txHistory'

type S = WalletState
type G = WalletGetters
type M = WalletMutations
type A = WalletActions

export class WalletState {
  public ethAccount: Hash | null = null

  public type: string | null = null

  public chainId: number | string | null = null

  public isLoadingConnection = false

  public walletMeta: Meta | null = null
}

export class WalletGetters extends Getters<S> {
  public get isConnected() {
    return !!(
      this.state.chainId
      && this.state.ethAccount
    )
  }

  public get isInjectedWallet() {
    return (
      !this.state.walletMeta?.isGnosisSafe
    )
  }

  public get isAvailableChainSwitch() {
    return this.state.walletMeta?.isAvailableChainSwitch
  }

  public get isLocked() {
    return this.state.ethAccount === EMPTY_ADDRESS
  }
}

class WalletMutations extends Mutations<S> {
  public setState(options: {
    [K in keyof S]: { k: K; v: S[K] }
  }[keyof S]) {
    // @ts-ignore: TODO ts(2322)
    this.state[options.k] = options.v
  }

  public disconnect() {
    this.state.ethAccount = null
    this.state.type = null
    this.state.chainId = null
    this.state.isLoadingConnection = false
    this.state.walletMeta = null
  }
}

export class WalletActions extends Actions<S, G, M, A> {
  private appStore!: Context2<typeof AppStoreModule>

  private txHistoryStore!: Context2<typeof TxHistoryStoreModule>

  public $init(store: Store<unknown>) {
    this.appStore = AppStoreModule.context(store) as Context2<typeof AppStoreModule>
    this.txHistoryStore = TxHistoryStoreModule.context(store) as Context2<typeof TxHistoryStoreModule>
  }

  public async connect(walletData: WalletDataInput) {
    try {
      await this.actions.connectToHandler(walletData)
    } catch {
      this.actions.disconnect()
    }
  }

  public async detectGnosisSafeTx({ hash, chainId }: {hash: string; chainId: number}) {
    const { type, walletMeta } = this.state
    const isSafe = type === 'Safe' || (walletMeta?.isGnosisSafe && await checkIsGnosis(hash, chainId))
    return isSafe
  }

  private async connectToHandler(walletData: WalletDataInput) {
    const setState = createSetState<A>(this)

    try {
      setState({ k: 'walletMeta', v: walletData.meta })
      setState({ k: 'type', v: walletData.providerName })

      await this.actions.accountsChanged(walletData.address)
    } catch (error) {
      const isLocked = /Already processing eth_requestAccounts. Please wait/g.test((error as Error)?.message)
      if (isLocked) {
        const ethAccount = isLocked ? EMPTY_ADDRESS : null
        setState({ k: 'chainId', v: null })
        setState({ k: 'ethAccount', v: ethAccount })
      }

      // eslint-disable-next-line no-console
      console.warn(`[App err]: connectTo wallet "${walletData.providerName}"`, error)
      return Promise.reject(error)
    }
    return true
  }

  public disconnect() {
    this.mutations.disconnect()
  }

  public chainChanged(chainId: number | null) {
    this.mutations.setState({ k: 'chainId', v: chainId })
  }

  private async accountsChanged(address?: Hash) {
    const setState = createSetState<A>(this)

    if (address === this.state.ethAccount) {
      return
    }
    setState({ k: 'ethAccount', v: address || EMPTY_ADDRESS })
    void this.txHistoryStore.actions.startWaitPendingAll()
    if (address) {
      await this.appStore.actions.updateBalance(address)
    }
  }

  public async updateRpc(url: string) {
    const { chainId } = this.state
    if (!chainId || typeof chainId === 'string') return

    const networkService = new NetworkService(chainId)
    networkService.setRpcUrl(url)

    await this.actions.setRpc()
  }

  private async setRpc() {
    await this.appStore.actions.updateRpc()
  }
}

const storeModule = new Module({
  namespaced: true,
  state: WalletState,
  getters: WalletGetters,
  mutations: WalletMutations,
  actions: WalletActions,
})


const useStore = createComposable(storeModule) as ComposableFn<typeof storeModule>

export {
  storeModule as WalletStoreModule,
  useStore as useWalletStoreModule,
}

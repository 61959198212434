import type {
  ComposableFn,
} from 'vuex-smart-module/lib'

import type {
  IAirdropPublicItem,
  IAirdropForRecipientItem,
} from '@/services'

import type {
  IAirdropPublicPagination,
} from './types'

import { Hash } from 'viem'
import groupBy from 'lodash/groupBy'
import { cancelPrevious } from 'utils-decorators'

import {
  Module,
  Getters,
  Actions,
  Mutations,
  createComposable,
} from 'vuex-smart-module'

import {
  sentryLogger,
  ApiAirdropService,
  MerkleTreeService,
} from '@/services'

import {
  DEFAULT_PAGINATION_LIMIT,
} from '@/utils/constants/params'

import {
  isCancel,
  createSetState,
  createPagination,
} from './types'

type S = AirdropsState
type G = AirdropsGetters
type M = AirdropsMutations
type A = AirdropsActions

type IAirdropsMetaMap = Record<string, {
  cancelled: boolean;
  done: boolean;
}>

export class AirdropsState {
  public airdrops: IAirdropPublicItem[] = []

  public airdropsPagination: IAirdropPublicPagination = createPagination()

  public airdropsMetaMap: IAirdropsMetaMap = {}

  public airdropsRecipient: IAirdropForRecipientItem[] = []

  public claimedMap: Record<string, boolean> = {}
}

class AirdropsGetters extends Getters<S> {
}

class AirdropsMutations extends Mutations<S> {
  public setState(options: {
    [K in keyof S]: { k: K; v: S[K] }
  }[keyof S]) {
    // @ts-ignore: TODO ts(2322)
    this.state[options.k] = options.v
  }
}

class AirdropsActions extends Actions<S, G, M, A> {
  public async updateAirdropsMetaMap(payload: {
    airdrops: {
      publicName: string;
      chainId: number;
    }[];
  }) {
    const setState = createSetState<A>(this)

    try {
      const result = await this.actions.getAirdropsMetaMap(payload)
      setState({ k: 'airdropsMetaMap', v: result })
    } catch (error) {
      // setState({ k: 'airdropsMetaMap', v: {} })
      if (!isCancel(error)) {
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return true
  }

  @cancelPrevious()
  public async getAirdropsMetaMap(payload:
    Parameters<A['updateAirdropsMetaMap']>[0]) {
    const groups = groupBy(payload.airdrops, 'chainId')
    const { airdropsMetaMap } = this.state

    const initialValue: Record<string, { cancelled: boolean; done: boolean }> = {}

    const result = await Object.values(groups).reduce(async (acc, airdrops) => {
      const airdropNames = airdrops
        .map((_) => _.publicName)
        .filter((publicName) => {
          const { cancelled, done } = airdropsMetaMap[publicName] || {}
          return !(cancelled || done)
        })

      const [{ chainId }] = airdrops
      const treeService = new MerkleTreeService(chainId)
      const response = await treeService.getAirdropsMetaMap(airdropNames)

      const map = await acc
      return { ...map, ...response }
    }, Promise.resolve(initialValue))

    return { ...airdropsMetaMap, ...result } || airdropsMetaMap
  }

  public async updateAirdrops(payload: {
    owner?: string | null;
    chainId?: number | null;
    page: number;
    limit?: number;
  } | void) {
    const setState = createSetState<A>(this)

    try {
      const { pagination, data } = await this.actions.getAirdrops(payload)
      setState({ k: 'airdrops', v: data })
      setState({ k: 'airdropsPagination', v: pagination })
    } catch (error) {
      if (!isCancel(error)) {
        setState({ k: 'airdrops', v: [] })
        setState({ k: 'airdropsPagination', v: createPagination() })
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return true
  }

  @cancelPrevious()
  public async getAirdrops(payload:
    Parameters<A['updateAirdrops']>[0]) {
    const {
      owner,
      chainId,
      page,
      limit = DEFAULT_PAGINATION_LIMIT,
    } = payload || { page: 1 }

    const api = new ApiAirdropService()
    const response = await api.getAll(owner, { chainId, page, limit })

    if (!response.data) {
      return {
        pagination: createPagination(),
        data: [],
      }
    }

    const { data, ...pagination } = response

    return {
      pagination,
      data,
    } as {
      pagination: IAirdropPublicPagination;
      data: typeof data;
    }
  }

  public async updateAirdropsRecipient(payload: {
    chainId?: number | null;
    recipient?: Hash;
  }) {
    const setState = createSetState<A>(this)
    const { recipient, chainId } = payload

    if (!recipient) {
      setState({ k: 'airdropsRecipient', v: [] })
      return true
    }

    try {
      const result = await this.actions.getAirdropsRecipient({ recipient, chainId })
      setState({ k: 'airdropsRecipient', v: result || [] })
    } catch (error) {
      setState({ k: 'airdropsRecipient', v: [] })
      if (!isCancel(error)) {
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return true
  }

  @cancelPrevious()
  public async getAirdropsRecipient(payload:{
    chainId?: number | null;
    recipient: Hash;
  }) {
    const { recipient } = payload

    const api = new ApiAirdropService()

    const response = await api.getAllForRecipient(recipient)
    return response.data
  }

  public async updateClaimedMap(payload: {
    recipient: Hash;
  }) {
    const setState = createSetState<A>(this)
    const { airdropsRecipient: airdrops } = this.state
    const { recipient } = payload

    try {
      const options = { recipient, airdrops }
      const result = await this.actions.getClaimedMap(options)
      setState({ k: 'claimedMap', v: result })
    } catch (error) {
      setState({ k: 'claimedMap', v: {} })
      if (!isCancel(error)) {
        sentryLogger.capture(error)
        return Promise.reject(error)
      }
    }

    return true
  }

  @cancelPrevious()
  public async getClaimedMap(payload: {
    recipient: Hash;
    airdrops: {
      publicName: string;
      chainId: number;
    }[];
  }) {
    const { recipient } = payload
    const groups = groupBy(payload.airdrops, 'chainId')

    const data = await Object.values(groups).reduce(async (acc, airdrops) => {
      const [{ chainId }] = airdrops
      const airdropNames = airdrops.map((_) => _.publicName)
      const treeService = new MerkleTreeService(chainId)
      const result = await treeService.getClaimedMapByRecipient(recipient, airdropNames)

      const map = await acc
      return { ...map, ...result }
    }, Promise.resolve({} as Record<string, boolean>))

    return data || {}
  }
}

const storeModule = new Module({
  namespaced: true,
  state: AirdropsState,
  getters: AirdropsGetters,
  mutations: AirdropsMutations,
  actions: AirdropsActions,
})

const useStore = createComposable(storeModule) as ComposableFn<typeof storeModule>

export {
  storeModule as AirdropsStoreModule,
  useStore as useAirdropsStoreModule,
}

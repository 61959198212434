import {
  Hash,
  toHex,
  keccak256,
  isAddress,
  getAddress,
  parseUnits,
  formatUnits,
} from 'viem'

const NATIVE_DECIMALS = 18
export function fromWei(balance: string | bigint, unitName = NATIVE_DECIMALS) {
  return formatUnits(BigInt(balance), unitName)
}

export function toWei(balance: string | bigint, unitName = NATIVE_DECIMALS) {
  return parseUnits(balance.toString(), unitName)
}

export const toChecksumAddress = (address: Hash | string) => {
  try {
    return getAddress(address)
  } catch {
    return address as Hash
  }
}

export function compareAddresses(address1?: Hash | null | string, address2?: Hash | null | string) {
  try {
    if (!address1 || !address2) {
      return false
    }
    return getAddress(address1) === getAddress(address2)
  } catch {
    return false
  }
}

export function isValidAddress(address?: Hash | string | null) {
  if (!address) {
    return false
  }
  return address.length >= 42 && isAddress(address)
}

export function stringToHash(str: string) {
  return keccak256(toHex(str))
}


import { Hash } from 'viem'
import { useConfig } from '@wagmi/vue'
import { watchAsset } from 'viem/actions'
import { getConnectorClient } from '@wagmi/core'

type AddToWalletParams = {
  address: Hash;
  symbol: string;
  decimals: number;
}

export function useWatchAsset() {
  const config = useConfig()

  return async (options: AddToWalletParams) => {
    const walletClient = await getConnectorClient(config)

    return watchAsset(walletClient, {
      type: 'ERC20',
      options,
    })
  }
}
